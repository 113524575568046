import './style.less';

import React, { useState } from 'react';

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

import { SECTION_DATA } from 'modules/directory/containers/Directory';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

const DirectoryMenu = () => {
  const { core } = useStore();
  const checkAccess = (menuItem) => !menuItem.access || core.permissions[menuItem.access];
  const [isExtraOpened, setIsExtraOpened] = useState(false);

  const list = SECTION_DATA.filter(checkAccess);

  if (list.length > 0) {
    const menuItems = list.map((i) => ({
      ...i,
      key: i.id,
      label: (
        <NavLink key={i.id} to={`/directories${i.href}`} className="dir-menu__link">
          {i.title}
        </NavLink>
      ),
    }));
    const expandIcon = isExtraOpened ? <UpOutlined /> : <DownOutlined />;
    return (
      <nav className="dir-menu">
        <Menu
          mode="horizontal"
          items={menuItems}
          overflowedIndicator={
            <div>
              {translate('more')}
              {expandIcon}
            </div>
          }
          onOpenChange={(e) => setIsExtraOpened(e.length > 0)}
          triggerSubMenuAction={'click'}
        />
      </nav>
    );
  }

  return null;
};

export default observer(DirectoryMenu);
