import { Feature, Point, Polygon } from 'geojson';

import { getListMapView } from 'modules/coverages/components/CoveragesList/helpers';
import { ICoverage, ICoveragesPolygon } from 'modules/coverages/models/types';
import { ILocation } from 'modules/locations/models/types';

export type IMapView = Feature<Point> | Feature<Polygon>;

export const getMapView = (
  polygon: ICoveragesPolygon,
  coveragesList: ICoverage[],
  location: ILocation,
  action: string,
): IMapView[] => {
  const viewPolygons = polygon ? [polygon] : getListMapView(coveragesList);
  let viewCenter = [];
  let viewWarehouses = [];
  if (action === 'create') {
    if (location && location.point) {
      viewCenter = [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [location.point.lon, location.point.lat],
          },
        },
      ];
    }
    if (location && location.warehouses) {
      viewWarehouses = location.warehouses.map((w) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [w.point.lon, w.point.lat],
        },
      }));
    }
  }
  return [...viewCenter, ...viewWarehouses, ...viewPolygons];
};
